import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

interface ServiceProps {
  title: string;
  description: string;
  icon: React.ReactNode;
}

const Service: React.FC<ServiceProps> = ({ title, description, icon }) => (
  <motion.div 
    className="bg-white p-6 rounded-lg shadow-lg transition-all duration-300 hover:shadow-xl"
    whileHover={{ scale: 1.05 }}
  >
    <div className="text-4xl text-blue-600 mb-4">{icon}</div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-gray-600">{description}</p>
  </motion.div>
);

interface SponsorProps {
  name: string;
  logo: string;
}

const Sponsor: React.FC<SponsorProps> = ({ name, logo }) => (
  <motion.div 
    className="flex items-center justify-center p-8 bg-white rounded-lg shadow-lg"
    whileHover={{ scale: 1.05 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <img src={logo} alt={name} className="h-24 w-auto max-w-full object-contain transition-all duration-300" />
  </motion.div>
);

interface TeamMemberProps {
  name: string;
  title: string;
  background: string;
  image: string;
}

const TeamMember: React.FC<TeamMemberProps> = ({ name, title, background, image }) => (
  <div className="flex flex-col items-center text-center">
    <img src={image} alt={name} className="w-48 h-48 rounded-full mb-4 object-cover" />
    <h3 className="text-xl font-semibold mb-2">{name}</h3>
    <h4 className="text-lg text-gray-600 mb-2">{title}</h4>
    <p className="text-sm text-gray-500">{background}</p>
  </div>
);

export default function LandingPage() {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="min-h-screen">
      <div className="h-screen relative overflow-hidden">
        <div 
          className="absolute inset-0 bg-cover bg-center z-0" 
          style={{backgroundImage: "url('/background.png')"}}
        />
        <div className="absolute inset-0 bg-black opacity-50 z-10" />
        
        <div className="relative z-20 h-full flex flex-col items-center justify-center px-4 text-center">
          <motion.div
            className="mb-0"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <img src="/kc_logo.png" alt="KatlaCode Logo" className="w-96 h-auto" />
          </motion.div>
          <motion.h1 
            className="text-5xl sm:text-6xl md:text-7xl lg:text-8xl font-bold text-white"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            <motion.span
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.5, duration: 0.8 }}
              className="block sm:inline"
            >
              Katla
            </motion.span>
            <motion.span
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.8, duration: 0.8 }}
              className="block sm:inline"
            >
              Code
            </motion.span>
          </motion.h1>
          <motion.p
            className="text-lg sm:text-xl text-white mt-4 max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1.2, duration: 1 }}
          >
            Empowering healthcare with innovative AI solutions
          </motion.p>
        </div>
      </div>

      <div className={`bg-white transition-all duration-500 ${scrollY > 100 ? "opacity-100" : "opacity-0"}`}>
        <section className="container mx-auto py-20">
          <h2 className="text-4xl font-bold mb-12 text-center">Our Services</h2>
          <p className="text-xl text-gray-600 text-center mb-12 max-w-3xl mx-auto">
            At KatlaCode, we're revolutionizing healthcare with cutting-edge AI solutions. Our services are designed to streamline processes, enhance patient care, and empower medical professionals.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            <Service 
              title="AI-Powered Compliance" 
              description="Leverage advanced AI to optimize clinical coding and financing by integrating AI solutions into workflows."
              icon={<i className="fas fa-shield-alt"></i>}
            />
            <Service 
              title="Automated Workflows" 
              description="Optimize healthcare operations with intelligent automation, freeing up valuable time for patient care."
              icon={<i className="fas fa-cogs"></i>}
            />
            <Service 
              title="Smart Document Processing" 
              description="Transform document handling with AI-driven analysis, enhancing accuracy and efficiency in medical record management."
              icon={<i className="fas fa-file-medical-alt"></i>}
            />
          </div>
        </section>

        <section className="bg-gray-50 py-16">
          <div className="container mx-auto">
            <h2 className="text-4xl font-bold mb-12 text-center">The Team</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
              <TeamMember
                name="Adeline Tracz"
                title="CEO and Innovation Leader"
                background="Leader with extensive experience in healthcare innovation and AI integration at Landspítali hospital."
                image="/adeline.jpg"
              />
              <TeamMember
                name="Arnar Þór Guðjónsson"
                title="MD, Head of ENT Surgery"
                background="Surgeon bringing clinical expertise and practical insights to our AI solutions in healthcare."
                image="/arnar.jpg"
              />
              <TeamMember
                name="Hafsteinn Einarsson"
                title="PhD, Associate Professor of CS"
                background="ETHZ alumnus with a focus on applied AI, bridging academia and industry."
                image="/hafsteinn.png"
              />
              <TeamMember
                name="Haraldur Orri Hauksson"
                title="M.Sc., Software Engineer"
                background="ETHZ graduate with expertise in developing robust and scalable software solutions for complex problems."
                image="/haraldur.png"
              />
            </div>
          </div>
        </section>

        <section className="py-20 bg-gray-50">
          <div className="container mx-auto">
            <h2 className="text-4xl font-bold mb-4 text-center">Strategic Partnerships</h2>
            <p className="text-xl text-gray-600 text-center mb-12 max-w-3xl mx-auto">
              Collaborating with leading institutions to drive innovation in healthcare.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-4 gap-12">
              <Sponsor name="University of Iceland" logo="hi_logo.png" />
              <Sponsor name="Technology Development Fund" logo="TechDevFund_Logo_EN.jpg" />
              <Sponsor name="Landspítali" logo="Landspitali_Logo.png" />
              <Sponsor name="Almannarómur" logo="almannaromur.png" />
            </div>
          </div>
        </section>

        <footer className="bg-gray-800 text-white py-12">
          <div className="container mx-auto px-4">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-center">
              <div className="text-center md:text-left">
                <h3 className="text-2xl font-bold mb-4">KatlaCode</h3>
                <p className="text-gray-300">Empowering healthcare with innovative AI solutions</p>
              </div>
              <div className="flex justify-center">
                <img src="/kc_logo.png" alt="KatlaCode Logo" className="w-24 h-auto" />
              </div>
              <div className="text-center md:text-right">
                <h4 className="text-xl font-semibold mb-4">Contact Us</h4>
                <p className="text-gray-300 mb-2">Email: info@katlacode.ai</p>
                <p className="text-gray-300">Reykjavík, Iceland</p>
              </div>
            </div>
            <div className="mt-8 pt-8 border-t border-gray-700 text-center text-gray-400">
              <p>&copy; {new Date().getFullYear()} KatlaCode. All rights reserved.</p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}